import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import JaegerLeCoultureCategoryBtns from '../../components/preowned/JaegerLeCoultureCategoryBtns'

// markup
const UsedJaegerlecoultremastercontrolwatches = () => {
  const data = useStaticQuery(
    graphql`
      query queryJaegerlecoultremastercontrol {
        products: allStrapiProduct(
          filter: { brand: { eq: "Jaeger LeCoulture" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Jaeger LeCoultre Master Control Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/jaeger-lecoultre/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Jaeger LeCoultre Master Control with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
              <StaticImage
                src="../../images/preowned/pre-owned-certified-used-jaeger-master-control-banner.jpg"
                alt="Pre-Owned Certified Used Jaeger LeCoulture Watches Header"
                aria-label="Used Jaeger LeCoulture Watch Site Header"
              />
            </a>
          </div>
          {/* BANNERS FOR THE JLC PAGES NEED TO BE UPDATED OR HAVE PLACE HOLDER */}
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className=" text-4xl pt-20">
              Buy Sell Trade Consign Repair Restore Jaeger Master Control Watches
            </h1>
            <h2>
              {' '}
              Buy Sell trade Consign Repair Restore
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Master Control
              </a>{' '}
              Watches
            </h2>
            <h3>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                {' '}
                Jaeger LeCoultre Master Control
              </a>{' '}
              WATCHES WITH GRAY AND SONS JEWELERS
            </h3>
            <h2 className="text-2xl text-red-700">Jaeger LeCoultre Master Control History </h2>
            <p>
              {' '}
              As we progress through the twenty-first century, more and more watch collectors are
              finding interest in timepieces from the 90s. This experimental era for watches
              consisted of smaller case sizes and the use of various precious metals became
              prominent, and soon popularized. However, during the ’90s, while major watch brands
              were releasing watches with all kinds of complications and case shapes, Jaeger
              Le-Coultre decided to take it back with their classic-style Master Control collection.
              This gimmick-less line of Master Control watches eventually became one of Jaeger
              LeCoultre’s most popular collections. While the iconic square-shaped Reverso line was
              being innovated with chronographs functions, sub-dials, and GMT duo-face
              complications, the Jaeger LeCoultre Master Control Collection carried a tame look with
              a modest round-shaped case and only the essential time complications. With intentions
              to re-popularize the ‘traditional’ watch appearance, the Jaeger LeCoultre Master
              Control collection became available to the public in 1992. Along with the classic
              Master Control model that offered the time and date function, the Jaeger LeCoultre
              Master Geographic was released which offered ‘travel-time’ complications for avid
              travelers and so was the Master Control Chronograph model. This line of Jaeger
              LeCoultre watches was the first collection to be taken under the ‘1000 Hours Control’
              quality control where watches undergo a rigorous certification process to deem it up
              to factory standards. This certification includes the watches being tested for more
              than 6 weeks under varying temperatures, various water depths to confirm their water
              resistance, durability tests to confirm their shock resistance, power reserve tests
              that assure watches keep running over time, and several other specific examinations.
              Watches that went through this process have an emblem on the caseback saying “1,000
              Hours Control” to confirm these high-powered mechanical calibers were of the highest
              quality. This caliber 889 movement was equipped with 36 jewels, gave 28,800 beats per
              hour, and a 38-hour power reserve. Jaeger LeCoultre made this high-quality movement
              very thin and other high-end brands such as IWC, Piaget, and Audemars Piguet used it
              in their watches as well. One remarkable model under the JLC Master collection which
              differed from the simplicity of the others is the Jaeger LeCoultre Master Compressor.
              Like the others, the Master Compressor also underwent the ‘1,000 Hours’, however, this
              model had a unique component to be tested which the others didn’t. This unique system
              invented by Jaeger LeCoultre is called the ‘Compressor Key System’ and consists of a
              screw-down crown that allowed one to open or close the crown with only a half-turn of
              the compression key. The compression key has red arrows to indicate the crown is open
              and white to indicate the crown is closed. This mechanism made it easier for one to
              screw the crown down and also made it a possibility for manual wind watches to have
              the capability of water resistance. For these remarkable assets, the Jaeger LeCoultre
              Master collection has gotten its fair share of recognition and love globally.
              <br />
              <br />
              Gray and Sons and their buying division &ensp;
              <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com</a> Will also buy your
              used{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Master Control{' '}
              </a>{' '}
              Watch for cash. We are the #1 independent watch dealer. Our expert, Master trained,
              watch repairmen will service your watch and replace your{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Jaeger LeCoultre Master Control
              </a>{' '}
              Watch battery. We have a huge in-stock inventory at amazing prices. Talk to a real
              representative now.
              <br />
              <br />
              <h3>
                Buying and Selling Pre-Owned{' '}
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  {' '}
                  Jaeger LeCoultre Master Control
                </a>{' '}
                watches with gray &amp; sons jewelers.
              </h3>
              <p>
                Since its release, the JLC Master Control has been a huge success. These elegant
                timepieces draw the attention of any watch enthusiast that comes near them. The
                Jaeger LeCoultre Master Control collection is one of Jaeger LeCoustre’s versatile
                pieces appropriate for almost any setting. Under the umbrella of Jaeger LeCoultre
                Master Control watches fall under the following references:
              </p>
              <li>
                {' '}
                &ensp;{' '}
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Popular Pre-Owned Used Jaeger LeCoultre Master Control Watch References and
                  Models:
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Master Control References: 145289, 140.6.80, Q4018420{' '}
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Master Geographic References: 142.8.92, Q4128420,
                  Q1422421
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Master Chronograph References: Q1532520, Q1538171,
                  174.8.C1{' '}
                </a>{' '}
              </li>
              <li>
                {' '}
                &ensp;
                <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                  Pre-Owned Jaeger LeCoultre Master Control Ultra Thin References: Q1308470,
                  Q12935E1, Q1302520{' '}
                </a>{' '}
              </li>
              <br />
              <br />
              Since 1980, <a href="/">Gray and Sons</a> Jewelers has been catering to clients all
              around the country with all their luxury needs. All of the services we provide are
              done in our very own store, here, in the heart of Miami, Florida. We are the top watch
              store in all of South Florida specializing in buying pre-owned Jaeger LeCoultre
              watches,Selling used Jaeger LeCoultre watches, repairing second-hand Jaeger LeCoultre
              watches, and trading genuine Jaeger LeCoultre timepieces. Gray and Sons Jewelers is
              the #1 all-stop shop for all Jaeger LeCoultre watch needs. Our store is located in
              South Florida in the city of Surfside across the street from the famous Bal Harbour
              Shops. Aside from buying Jaeger LeCoultre Watches and selling genuine pre-owned Jaeger
              LeCoultre watches, <a href="/">Gray and Sons</a>
              is also a certified repair center for all Swiss-made wristwatches. We have six
              master-trained watchmakers in-house with over 150 years of combined experience to
              repair Jaeger LeCoultre watches, including the reparation of a Jaeger LeCoultre
              Reverso. We take in repairs for swiss-made watches daily!
              <br />
              <br />
              For more details, visit our showroom in Surfside, Miami, or check our website{' '}
              <a href="/fine-watches/JaegerLeCoulture/">www.grayandsons.com</a>. Our company is
              proud to have qualified and trusted in-house watchmakers and jewelers who stand ready
              to assist our customers. Make the best choice and buy your dream certified pre-owned
              Jaeger LeCoulture watch for the best price. The finest selection of used Jaeger
              LeCoulture watches - only at{' '}
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                Gray &amp; Sons.
              </a>
              . Those looking to “get cash for my used Jaeger LeCoultre watch” or “sell my pre-owned
              Jaeger LeCoultre watch today” should visit <a href="/">www.grayandsons.com</a>
              or{' '}
              <a href="https://sellusyourjewelry.com/what-are-you-looking-to-sell-2/">
                {' '}
                www.sellusyourjewelry.com
              </a>{' '}
              to request a free watch quote to sell or trade-in for one of ours! We have a
              brick-and-mortar store located in the heart of Miami, Fl near cities like Sunny Isles,
              Haulover, Aventura, Hollywood, Fort Lauderdale, Miami Beach, Coconut Grove, and Coral
              Gables, Key Biscayne, and many more. On our website, we have LIVE representatives
              Viktoria and Rich who are specialists in buying and selling Jaeger LeCoultre Master
              Control watches. Our LIVE CHAT feature allows clients all around the nation to make
              all inquiries about Jaeger LeCoultre watches and possibly work out a deal in
              real-time. Gray and Sons Jewelers is the best and most reliable place to buy used
              pre-owned Jaeger LeCoultre watches. Our master-trained watchmakers assure every Jaeger
              LeCoultre watches in our inventory is certified authentic and serviced ready for wear
              with a 1-year warranty covering the movement.
              <br />
              <br />
              We are so excited to hear from you and have you sign up for our free monthly catalog.
              Visit the store, call or visit our website at <a href="/">www.grayandsons.com</a>.
              Lot’s of people are buying used and pre-owned Jaeger LeCoulture Watches, and many
              people are looking for a place to sell their used Jaeger LeCoulture Watches. Gray and
              Sons will do both. If you’re one of the people saying, “I want to sell my Jaeger
              LeCoulture wrist watch for cash” visit <a href="/">www.grayandsons.com</a> and&ensp;
              <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com</a>
              and we will buy your pre-owned Jaeger LeCoultre Master Control Watch for cash. Would
              you like to <a href="/chat-with-rich">MAKE AN OFFER</a> on watches and jewelry? Vika
              and Rich are live expert decision makers and ready to work with you now.
              <br />
              <br />
            </p>
            <div className="w-full flex mx-auto justify-center pb-24 pt-24">
              <a href="/fine-watches/other-watches-brands/jaeger-lecoultre/filter/model/reverso,reverso-duetto-duo,reverso-duo/">
                <button>Shop Jaeger LeCoultre Master Control Watches</button>{' '}
              </a>
            </div>
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK Jaeger LeCoulture WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>
      <iframe
        className="content-center"
        src="https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d7178.937737903439!2d-80.1235056!3d25.8869528!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x88d9b2992b408533%3A0x8d702d6011cda310!2sGray%20and%20Sons%20Jewelers%7C%20Luxury%20Estate%20Watches%20%26%20Jewelry%209595%20Harding%20Ave%20Surfside%2C%20FL%2033154!3m2!1d25.8869528!2d-80.1235056!5e0!3m2!1sen!2sus!4v1655907861510!5m2!1sen!2sus"
        width="1250"
        height="720"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      <section>
        <h2 className="text-center py-10">
          MORE Jaeger LeCoulture FROM GRAY &amp; SONS
          <section>
            <JaegerLeCoultureCategoryBtns />
          </section>
        </h2>
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/Ih7LroA6z4A'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default UsedJaegerlecoultremastercontrolwatches
